import { AccredibleAttributeTypes, AccredibleCredential } from '@accredible-frontend-v2/models';
import { AccredibleLocaleDatePipe } from '@accredible-frontend-v2/pipes/locale-date';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DEFAULT_ATTRIBUTES } from './default-attributes';

@Injectable({
  providedIn: 'root',
})
export class AccredibleAttributeService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _localeDatePipe: AccredibleLocaleDatePipe,
  ) {}

  /**
   * This method should replace attributes with data if available.
   * If data is not available and the attribute is not a custom one, nothing is displayed.
   * Else, if data is not available and the attribute is a custom one, the attribute is displayed.
   */
  replaceAttributes(
    str: string,
    partialCredential: Partial<AccredibleCredential>,
    dateFormat?: string,
    usePlainText = false,
  ): string {
    // Look for named or numerical attributes
    return str.replace(/\[([0-9a-z_]+)\.?([0-9a-z_]+)]/gi, (match, parent, key) => {
      let replacementText = '';
      if (partialCredential) {
        if (parent === 'credential') {
          replacementText = <string>partialCredential[key];
        } else if (partialCredential[parent]) {
          replacementText = <string>(<any>partialCredential[parent])[key];
        } else {
          // Don't show anything if there's no parent/custom object
          return '';
        }
      }

      if (usePlainText) {
        const htmlElement = this._document.createElement('div');
        htmlElement.innerHTML = replacementText ?? '';
        replacementText = htmlElement.textContent;
        htmlElement.remove();
      }

      // If we have credential data, then we can infer a custom type from that
      let type = 'text';
      if (
        parent === 'custom' &&
        partialCredential?.custom_type &&
        partialCredential?.custom_type[key]
      ) {
        type = partialCredential.custom_type[key];
      } else {
        // Otherwise, we can look in the default attributes
        const index = DEFAULT_ATTRIBUTES.findIndex(
          (attribute) => attribute.parent === parent && attribute.key === key,
        );
        if (index > -1) {
          type = DEFAULT_ATTRIBUTES[index].type;
        }
      }

      if (type === AccredibleAttributeTypes.DATE) {
        // TODO: Think of a better way to do this...
        // This is done to prevent an error when trying to render a date for a language that isn't available.
        // At present, we only load locales (in register-languages.ts) for available languages.
        try {
          replacementText = this._localeDatePipe.transform(
            replacementText,
            dateFormat || 'longDate',
            // We have to map 'no' -> 'nb' as we only support one version of Norwegian, 'nb', in v2
            (partialCredential.language === 'no' ? 'nb' : partialCredential.language) || 'en',
          );
        } catch {
          replacementText = this._localeDatePipe.transform(
            replacementText,
            dateFormat || 'longDate',
            'en',
          );
        }
      }

      return replacementText || '';
    });
  }
}
